<template>
    <div>
        <div v-if="pageCurrent.total < 1">
            <div class="header" style="display: flex; justify-content: center">
                <div>
                    <div style="margin-top: 150px">
                        <div><h1>查找常见故障情况解决方法</h1></div>
                        <div>
                            <p>
                                如果你的设备出现了故障,
                                可在此快速查询是否有简单的尝试修正或解决的方法。<br />
                                例子: AGV夹臂没反应或无法收回, 可查询:
                                AGV夹臂没反应，或不回收等
                            </p>
                        </div>
                    </div>

                    <div style="margin-top: 50px">
                        <el-form
                            ref="form"
                            :inline="true"
                            :model="form"
                            label-width="80px"
                            @submit.native.prevent
                        >
                            <el-form-item>
                                <el-input
                                    v-model="form.keyword"
                                    style="width: 800px"
                                    @keyup.enter.native="search"
                                ></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="search"
                                    >搜索</el-button
                                >
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="pageCurrent.total > 0">
            <div
                class="header"
                style="
                    border-bottom: 2px solid #c3c3c3;
                    margin-left: -40px;
                    margin-right: -40px;
                    padding: 8px 30px 8px 100px;
                "
            >
                <div>
                    <div><h4>查找常见故障情况解决方法</h4></div>
                </div>

                <div>
                    <el-form
                        ref="form"
                        :inline="true"
                        :model="form"
                        label-width="80px"
                        @submit.native.prevent
                    >
                        <el-form-item>
                            <el-input
                                v-model="form.keyword"
                                style="width: 800px"
                            ></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button
                                type="primary"
                                @click="search"
                                @keydown.enter="search"
                                >搜索</el-button
                            >
                        </el-form-item>
                    </el-form>
                </div>
            </div>

            <div
                class="content"
                style="
                    margin-left: 60px;
                    margin-top: 50px;
                    margin-bottom: 20px;
                    display: flow-root;
                "
            >
                <el-col
                    :span="18"
                    v-for="(data, index) in tableData"
                    :key="data.id + index"
                    style="margin-bottom: 30px"
                    @click.native="toInfo(data)"
                >
                    <el-card
                        :body-style="{ padding: '30px 30px 0px 30px' }"
                        shadow="hover"
                    >
                        <div style="margin-bottom: 20px">
                            <div style="font-size: 18px; font-weight: 600">
                                {{ data.abnormal_question }}
                            </div>
                        </div>

                        <el-form
                            ref="form"
                            :inline="true"
                            label-position="left"
                        >
                            <el-form-item
                                style="margin-right: 30px"
                                label="所属类目:"
                            >
                                <span>{{
                                    data.question_type.type_name
                                        ? data.question_type.type_name
                                        : '无关联'
                                }}</span>
                            </el-form-item>

                            <el-form-item
                                style="margin-right: 30px"
                                label="软硬类型:"
                            >
                                <span>{{ data.types }}</span>
                            </el-form-item>

                            <el-form-item
                                style="margin-right: 30px"
                                label="提交人:"
                                v-if="data.author"
                            >
                                <span>{{ data.author }}</span>
                            </el-form-item>

                            <el-form-item
                                style="margin-right: 30px"
                                label="内容更新次数:"
                            >
                                <span>{{ data.update_count }}</span>
                            </el-form-item>

                            <el-form-item
                                style="margin-right: 30px"
                                label="适用关键词:"
                                v-if="data.author"
                            >
                                <span>{{ data.question_suit }}</span>
                            </el-form-item>
                        </el-form>
                    </el-card>
                </el-col>
            </div>

            <Pagination
                style="margin-left: 50px"
                :total="pageCurrent.total"
                :page.sync="pageCurrent.currentPage"
                @pagination="search()"
                :limit.sync="pageCurrent.pageSize"
                v-if="pageCurrent.total > 0"
            />
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { equipmentFaultManageSearch } from '@/api/manage/operation/equipment_fault_manage.js';
import Pagination from '@/components/Pagination.vue';
export default {
    name: '',
    data() {
        return {
            form: {
                keyword: null
            },
            tableData: [],
            pageCurrent: {
                currentPage: 1,
                total: 0,
                pageCount: 10,
                pageSize: 10
            }
        };
    },
    components: {
        Pagination
    },
    methods: {
        search() {
            equipmentFaultManageSearch(this.form).then((res) => {
                console.log(this.tableData, 'new');
                this.tableData = res.data.result;
                this.pageCurrent.currentPage = res.data.currentPage;
                this.pageCurrent.pageCount = res.data.pageCount;
                this.pageCurrent.total = res.data.total;

                if (this.pageCurrent.total == 0) {
                    this.$message('暂无该故障情况或解决办法');
                }
            });
        },
        handleEnterKey() {
            this.search();
        },
        toInfo(data) {
            this.$router.push({
                name: 'equipmentFaultManage_info',
                query: {
                    id: data.id
                }
            });
        }
    }
};
</script>
<style lang="scss" scoped></style>
